<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ alias: 'dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ alias: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ alias: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link>
                </p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 mt-2 px-10">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">Create new postal address</h1>
                <!-- <p class="text-caption text-left mt-8">This will create an independent form for storing your data.</p> -->
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 px-10">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card tile elevation="4" class="pa-0">
                        <v-toolbar short flat color="white">
                            <v-toolbar-title class="green--text">Create new postal address</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="px-5">
                        <v-form @submit.prevent="onSubmitForm">
                            <p>
                                People may try to reach you by sending postal mail to this address.
                            </p>
                            <p>
                                Here you only need to choose the label. You will enter the address on the next page.
                            </p>
                            <v-text-field
                                ref="postalAddressLabelInput"
                                v-model="newPostalAddressLabel"
                                label="Label"
                                hint="HQ, Payments, Billing, Customer Service, Sales, etc."
                                :rules="newPostalAddressLabelRules"
                                validate-on-blur
                                color="teal darken-2"
                                required
                                type="text"
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn elevation="4" class="green white--text" @click="onSubmitForm" :disabled="!isFormComplete">
                                <span>Create</span>
                            </v-btn>
                            <v-btn text color="grey" @click="cancel">
                                <span>Cancel</span>
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style>
.v-input .v-input__prepend-outer {
    margin-left: 4px !important;
    padding-left: 4px !important;
}

.v-input .v-input__append-outer {
    margin-top: 0px !important;
    padding-top: 0px !important;
}
</style>

<script>
import { mapState } from 'vuex';

export default {
    data: () => ({
        domainList: [],

        error: null,
        website: null,
        // newFormAlias: null,
        // newFormAliasRules: [
        //     (v) => !!v || 'Display alias is required',
        //     (v) => !v || isValidFormAlias(compact(v)) || 'Form alias is required',
        // ],
        newPostalAddressMailbox: null,
        newPostalAddressMailboxRules: [
            (v) => !!v || 'Mailbox is required',
        ],
        newPostalAddressDomain: null,
        newPostalAddressDomainChoices: [], // will be loaded from server
        newPostalAddressLabel: null,
        newPostalAddressLabelRules: [
            (v) => !!v || 'Display name is required',
        ],
        // // TODO: add property for replyto checkbox
        // newPostalAddressReplyTo: null,
        // newPostalAddressReplyToRules: [
        //     // (v) => !!v || 'Mailbox is required',
        //     (v) => isValidEmail(compact(v)) || 'Reply-to must be a valid email address',
        // ],
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
        }),
        isFormComplete() {
            return typeof this.newPostalAddressLabel === 'string' && this.newPostalAddressLabel.trim().length > 0;
        },
        accountName() {
            return this.account?.name ?? 'Unknown';
        },
    },
    methods: {
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        /*
        async loadDomainList() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadDomainList: true });
                const response = await this.$client.account(this.$route.params.accountId).domain.search({ status: 'verified' });
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response && Array.isArray(response.list)) {
                    this.domainList = response.list;
                    this.newPostalAddressDomainChoices = response.list.map((item) => ({
                        text: item.name, // TODO: if domain record shows the domain has DMARC/DKIM/SPF already set up, maybe we show a checkmark or some other icon next to the name on the list?
                        value: item.name,
                    }));
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load domain list', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadDomainList: false });
            }
        },
        */
        // async loadWebsite(id) {
        //     try {
        //         this.$store.commit('loading', { loadWebsite: true });
        //         const response = await this.$client.account(this.$route.params.accountId).website.get(id);
        //         console.log(`loadWebsite: response ${JSON.stringify(response)}`);
        //         if (response) {
        //             this.website = response;
        //             // if the form is for a website, suggest an appropriate form label (user can still edit this)
        //             if (this.newPostalAddressLabel === null || this.newPostalAddressLabel.length === 0) {
        //                 this.newPostalAddressLabel = `Website content for ${this.website.label}`;
        //             }
        //         }
        //     } catch (err) {
        //         console.error('failed to load website', err);
        //         this.website = null;
        //     } finally {
        //         this.$store.commit('loading', { loadWebsite: false });
        //     }
        // },
        onSubmitForm() {
            if (!this.isFormComplete) {
                return;
            }
            this.createPostalAddress();
        },
        cancel() {
            // this.$emit('cancel');
            this.$router.go(-1);
        },
        async createPostalAddress() {
            try {
                this.error = false;
                this.$store.commit('loading', { createPostalAddress: true });
                console.log('createPostalAddress');
                const request = {
                    label: this.newPostalAddressLabel,
                };
                // if (this.website) {
                //     request.website_id = this.website.id;
                // }
                const response = await this.$client.account(this.$route.params.accountId).postalAddress.create(request);
                console.log('createPostalAddress response: %o', response);
                const { isCreated, id, error } = response;
                if (isCreated && id) {
                    // const form = { id, ...request };
                    // this.list.push(form); // this SHOULD work (https://vuejs.org/v2/guide/list.html#Mutation-Methods) but doesn't work.
                    this.$router.replace({ name: 'account-edit-postal-address', params: { accountId: this.$route.params.accountId, postalAddressId: id } });
                } else if (error) {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create postal address' });
                    this.error = error;
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create postal address' });
                    this.error = 'Request failed';
                }
                // this.$emit('created', { id });
            } catch (err) {
                console.error('failed to create postal address', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create postal address' });
            } finally {
                this.$store.commit('loading', { createPostalAddress: false });
            }
        },
    },
    mounted() {
        this.loadAccount();
        // this.loadDomainList();
        // if (this.$route.query.website_id) {
        //     this.loadWebsite(this.$route.query.website_id);
        // }
    },
};
</script>
